<template>
  <img
    class="a-logo-amgen"
    :src="require(`@/assets/${fileName}`)"
    alt="Logo Amgen"
  />
</template>

<script>
import { ref } from "vue";

export default {
  name: "ALogoAmgen",
  props: {
    white: {
      default: false,
      required: false,
      type: Boolean
    }
  },
  setup(props) {
    var fileName;

    if (props.white == false) {
      fileName = ref("amgen2.png");
    } else {
      fileName = ref("amgen2.png");
    }

    return {
      fileName
    };
  }
};
</script>
